import { useIntl } from "react-intl";
import { t } from "translations";

import {
  Contact,
  CountryWeSellTo,
  MembershipType,
  PaymentMethod,
  Subscription,
} from "apps-common/types";
import { track } from "apps-common/utils/analytics";

import { routes } from "../routes";
import { canUseExistingAddressForPaymentMethod } from "../utils/address";
import { getBillingInformation } from "../utils/getBillingInformation";
import {
  getPaymentMethodStatus,
  isActiveSubscription,
  isB2CMember,
  isEntitlementMember,
  isPendingSubscription,
} from "../utils/member";
import {
  getToPaymentPageCTAText,
  paymentMethodDetail,
} from "../utils/paymentMethod";

import { NavigationRow, PreviewRow, SectionCard } from "./SectionCard";
import { StatusRow } from "./SectionCard/StatusRow";

interface BillingMethodsProps {
  currentSubscription: Subscription;
  paymentMethod: PaymentMethod | undefined;
  dunningLevel: number;
  membershipType: MembershipType;
  pendingPrepaidMonths: number;
  isValidCurrency: boolean;
  billToContact: Contact | null;
  shipToContact: Contact | null;
  countryData: CountryWeSellTo[];
}

export const BillingInformation = ({
  currentSubscription,
  paymentMethod,
  dunningLevel,
  membershipType,
  pendingPrepaidMonths,
  isValidCurrency,
  billToContact,
  shipToContact,
  countryData,
}: BillingMethodsProps) => {
  const intl = useIntl();
  const {
    nextPaymentDate,
    nextBillingDate,
    pendingCancellation,
    endDate,
    subscriptionState,
    recurringFee,
  } = currentSubscription;
  const isB2CUser = isB2CMember(membershipType);
  const isEntitlementUser = isEntitlementMember(membershipType);
  const isPendingUser = isPendingSubscription(subscriptionState);
  const isActiveEntitlementUser =
    isEntitlementUser && isActiveSubscription(subscriptionState);
  const paymentMethodStatus = getPaymentMethodStatus(paymentMethod);

  const paymentSectionTitle = isB2CUser
    ? intl.formatMessage({ id: "membership_hub_billing_information" })
    : intl.formatMessage({ id: "membership_hub_product_page_title" });
  const onlyShowBillingStatusAlone = isActiveEntitlementUser || isPendingUser;

  const {
    title: lokalisedBillingStatusTitle,
    content: lokalisedBillingStatusSubText,
    color,
    show,
  } = getBillingInformation({
    paymentMethodStatus,
    nextPaymentDate: nextPaymentDate ?? nextBillingDate,
    pendingCancellation,
    membershipType,
    subscriptionState,
    dunningLevel,
    endDate,
    billingPeriod: recurringFee?.billingPeriod,
    pendingPrepaidMonths,
    isValidCurrency,
  });

  const paymentDetails = paymentMethod && paymentMethodDetail(paymentMethod);

  const isExistingAddressValid = canUseExistingAddressForPaymentMethod(
    shipToContact,
    billToContact,
    countryData,
  );

  const {
    lokaliseLabelKeyName: ToPaymentLinkLabelKeyName,
    track: { cta },
    href,
    color: toPaymentLinkLabelColor,
  } = getToPaymentPageCTAText(
    currentSubscription.renewalState,
    paymentMethod,
    isExistingAddressValid,
  );

  return (
    <SectionCard title={paymentSectionTitle}>
      <StatusRow
        label={lokalisedBillingStatusTitle}
        value={lokalisedBillingStatusSubText}
        color={color}
        isLast={onlyShowBillingStatusAlone}
      />
      {show.paymentDetails && paymentDetails && (
        <PreviewRow
          label={t("membership_hub_billing_payment_details")}
          rowValues={paymentDetails}
        />
      )}
      {show.membershipPlanCTA && (
        <NavigationRow
          label={intl.formatMessage({
            id: "membership_hub_view_membership_plan",
          })}
          href={routes.membershipPlanOverview}
          onClick={() => {
            track({
              event: "Link Clicked",
              payload: {
                cta: "view_plan",
                location: "body",
              },
            });
          }}
        />
      )}
      {show.paymentCTA && (
        <NavigationRow
          label={intl.formatMessage({ id: ToPaymentLinkLabelKeyName })}
          href={href}
          color={toPaymentLinkLabelColor}
          onClick={() => {
            track({
              event: "Link Clicked",
              payload: {
                cta,
                location: "body",
              },
            });
          }}
        />
      )}
      {show.billingHistoryCTA && (
        <NavigationRow
          label={intl.formatMessage({
            id: "membership_hub_view_billing_history",
          })}
          href={routes.billingHistory}
          onClick={() => {
            track({
              event: "Link Clicked",
              payload: {
                cta: "billing_history",
                location: "body",
              },
            });
          }}
        />
      )}
    </SectionCard>
  );
};
