import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import { AccountApi, Configuration } from "oura-account-api-client";

const api = new AccountApi(
  new Configuration({ basePath: import.meta.env.VITE_ACCOUNT_API_URL }),
);

export interface InvoiceGetResponse {
  file: File;
  invoiceId: string;
}

export const useGetInvoices = (selectedInvoices: string[]) =>
  useQueries({
    combine: (queries) => {
      const mapData = (results: typeof queries) =>
        results.every((r) => !!r.data) ? results.map((r) => r.data) : undefined;

      return {
        data: mapData(queries),
        errors: queries.map((r) => r.error),
        isFetching: queries.some((r) => r.isFetching),
        refetch: async () => {
          const allResults = await Promise.all(
            queries.map(async (r) => {
              if (!r.isSuccess) {
                return await r.refetch();
              }
              return r;
            }),
          );

          return mapData(allResults);
        },
      };
    },
    queries: selectedInvoices.map<UseQueryOptions<InvoiceGetResponse>>(
      (invoiceId) => ({
        queryKey: ["getInvoices", invoiceId],
        queryFn: async (): Promise<InvoiceGetResponse> => {
          const response = await api.getInvoice(
            { invoiceId: invoiceId },
            { withCredentials: true, responseType: "blob" },
          );
          return { file: response.data, invoiceId };
        },
        retry: false,
        staleTime: Infinity,
        enabled: false,
      }),
    ),
  });
