import { ChangeEventHandler } from "react";

import { t } from "translations";

import { Pagination, usePagination } from "apps-common/components/Pagination";
import { AnnualReport, Currency } from "apps-common/types";
import { logger } from "apps-common/utils/logger";
import { CTALoader } from "ui";
import { DownloadIcon } from "ui/components/Icons";
import { Background } from "ui/styles/layout";

import { useGetAnnualReports } from "../../hooks/useGetAnnualReports";
import { clientDownloadFile, zipMultipleFiles } from "../../utils/fileDownload";

import {
  DownloadButton,
  InvoiceComponent,
  Ul,
  ViewContainer,
} from "./commonComponents";
import { FileSelectionAction } from "./fileSelectionReducer";

const fileNameFromYear = (year: string): string =>
  `oura-annual-report-${year}.pdf`;

const PAGESIZE = 10;

interface AnnualReportViewProps {
  id: string;
  annualReports: AnnualReport[];
  currency: Currency;
  selectedReports: string[];
  dispatch: React.Dispatch<FileSelectionAction>;
}

export const AnnualReportView = ({
  id,
  annualReports,
  currency,
  selectedReports,
  dispatch,
}: AnnualReportViewProps) => {
  const { isFetching, refetch } = useGetAnnualReports(selectedReports);

  const { currentPageObjects: pagedReports } = usePagination(
    PAGESIZE,
    annualReports,
  );

  const annualReportComponents = pagedReports.map(({ id, year, amount }) => {
    const isSelected = selectedReports.includes(id);
    const dateOrYear = year.toString();
    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { checked } = e.target;
      if (checked) {
        dispatch({ type: "add", filesToAdd: [id] });
      } else {
        dispatch({ type: "delete", fileToDelete: id });
      }
    };

    return (
      <InvoiceComponent
        key={year}
        amountText={amount.toLocaleString(undefined, {
          style: "currency",
          currency,
        })}
        dateOrYear={dateOrYear}
        isSelected={isSelected}
        onChange={onChange}
      />
    );
  });

  const onDownloadClick = async () => {
    logger.debug("Selected reports", { selectedReports });
    await refetch().then((fetchedReports) => {
      logger.debug("Fetched reports", { fetchedReports });

      if (!fetchedReports) {
        logger.debug("Report refetch returned undefined", {
          fetchedReports,
        });
        return;
      }

      if (fetchedReports.length > 1) {
        void zipMultipleFiles(
          fetchedReports.map((d) => ({
            data: d.file,
            fileName: fileNameFromYear(d.year),
          })),
          "oura-annual-reports.zip",
        );
      } else {
        clientDownloadFile(
          fetchedReports[0].file,
          "application/pdf",
          fileNameFromYear(fetchedReports[0].year),
        );
      }
    });
  };

  return (
    <ViewContainer role="tabpanel" id={id}>
      <Background>
        <Ul>{annualReportComponents}</Ul>
      </Background>
      <Pagination pageSize={PAGESIZE} paginatedObjects={annualReports} />
      <DownloadButton
        $margin="24px 0"
        disabled={selectedReports.length === 0 || isFetching}
        onClick={onDownloadClick}
      >
        {isFetching && <CTALoader />}
        {!isFetching && (
          <>
            {t("membership_hub_download_annual_report")}
            <DownloadIcon />
          </>
        )}
      </DownloadButton>
    </ViewContainer>
  );
};
