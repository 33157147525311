import { useQueries } from "@tanstack/react-query";
import { AccountApi, Configuration } from "oura-account-api-client";

const api = new AccountApi(
  new Configuration({ basePath: import.meta.env.VITE_ACCOUNT_API_URL }),
);

export const useGetAnnualReports = (selectedReports: string[]) =>
  useQueries({
    combine: (queries) => {
      const mapData = (results: typeof queries) =>
        results.every((r) => !!r.data) ? results.map((r) => r.data) : undefined;

      return {
        data: mapData(queries),
        errors: queries.map((r) => r.error),
        isFetching: queries.some((r) => r.isFetching),
        refetch: async () => {
          const allResults = await Promise.all(
            queries.map(async (r) => {
              if (!r.isSuccess) {
                return await r.refetch();
              }
              return r;
            }),
          );

          return mapData(allResults);
        },
      };
    },
    queries: selectedReports.map((year) => ({
      queryKey: ["getAnnualReport", year],
      queryFn: async (): Promise<{ file: File; year: string }> => {
        const response = await api.getAnnualReport(
          { year: Number(year) },
          { withCredentials: true, responseType: "blob" },
        );
        return {
          file: response.data,
          year,
        };
      },
      retry: false,
      stateTime: Infinity,
      enabled: false,
    })),
  });
