import { ChangeEventHandler, ReactNode } from "react";

import { styled } from "styled-components";

import { Button, Checkbox } from "ui";
import { Text } from "ui/styles/text";
import { media } from "ui/themes/signup";

export const Ul = styled.ul(
  ({ theme }) => `
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:first-child) {
    border-top: 1px solid ${theme.colors.grayMedium}
  }
`,
);

const Li = styled.li`
  padding: 24px 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadButton = styled(Button)`
  margin: 40px 0;
  gap: 10px;
  align-self: center;

  ${media.xlarge`
    max-width: 60%;
    align-self: flex-end;
  `}
`;

export const ViewContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface InvoiceComponentProps {
  dateOrYear: string;
  amountText: ReactNode;
  isSelected: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const InvoiceComponent = ({
  dateOrYear,
  amountText,
  isSelected,
  onChange,
}: InvoiceComponentProps) => (
  <Li>
    <Checkbox checked={isSelected} onChange={onChange}>
      {dateOrYear}
    </Checkbox>
    <Text>{amountText}</Text>
  </Li>
);
