import chunk from "lodash/chunk";
import { useSearchParams } from "react-router-dom";
import { styled } from "styled-components";

export function usePagination<T>(pageSize: number, pagedObjects: T[]) {
  const [params, setParams] = useSearchParams();
  const currentPage = Number.parseInt(params.get("page") ?? "1", 10);
  const pagedElements = chunk(pagedObjects, pageSize);

  return {
    currentPage,
    currentPageObjects: pagedElements[currentPage - 1],
    pageCount: pagedElements.length,
    navigateToPage: (pageNumber: number) => {
      setParams((prev) => ({ ...prev, page: pageNumber.toString() }));
    },
  };
}

const PaginationContainer = styled.div(
  ({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 0 0 0;
  .pageCard {
    height: 40px;
    padding: 0 8px;
    background: transparent;
    border: 0px;
    font-size: ${theme.fontSizes.medium};
    font-weight: 600;
    box-sizing: border-box;
    color: ${theme.colors.white};
    cursor: pointer;
  }
  .pageCard.active {
    color: ${theme.colors.blueLight};
  }
  .pageCard:disabled {
    color: ${theme.colors.grayLight}
  }
`,
);

const PageButton = styled.button<{ $active?: boolean }>(
  ({ theme, $active }) => `
    height: 40px;
    padding: 0 8px;
    background: transparent;
    border: 0px;
    font-size: ${theme.fontSizes.medium};
    font-weight: 600;
    box-sizing: border-box;
    color: ${$active ? theme.colors.blueLight : theme.colors.white};
    cursor: pointer;
     &[disabled] {
      color: ${theme.colors.grayLight}
    }
`,
);

interface PaginationProps {
  pageSize: number;
  paginatedObjects: unknown[];
}

export const Pagination = ({ pageSize, paginatedObjects }: PaginationProps) => {
  const {
    currentPage,
    pageCount,
    navigateToPage: setPage,
  } = usePagination(pageSize, paginatedObjects);

  if (pageCount === 1) {
    return null;
  }

  const pageButtons = [...Array(pageCount).keys()].map((_, idx) => {
    const page = idx + 1;
    return (
      <PageButton
        key={idx}
        $active={currentPage === page}
        onClick={() => setPage(page)}
      >
        {page}
      </PageButton>
    );
  });

  return (
    <PaginationContainer>
      <PageButton
        onClick={() => {
          if (currentPage > 1) setPage(currentPage - 1);
        }}
        disabled={currentPage <= 1}
      >{`<`}</PageButton>

      {pageButtons}

      <PageButton
        onClick={() => {
          if (currentPage < pageCount) setPage(currentPage + 1);
        }}
        disabled={currentPage === pageCount}
      >{`>`}</PageButton>
    </PaginationContainer>
  );
};
