import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { t } from "translations";

import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { BillingPeriod, Channel } from "apps-common/types";
import { throwError } from "apps-common/utils/errorHandler";
import { periodToProductPriceKey } from "apps-common/utils/productLocalization";
import { Header, Loader, VerticalCard } from "ui";
import { StyledCheckIcon } from "ui/components/Icons/Check";
import { StyledPromotionsIcon } from "ui/components/Icons/Promotions";
import { StyledSelfImprovementIcon } from "ui/components/Icons/SelfImprovement";
import { StyledUpdatesIcon } from "ui/components/Icons/Updates";
import { MainContainer } from "ui/styles/containers";
import { HeaderText } from "ui/styles/text";
import { media } from "ui/themes/signup";

import { FeatureText } from "../components/FeatureText";
import { routes } from "../routes";
import { isEntitlementMember } from "../utils/member";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${media.large`
    justify-content: space-between;
    flex-wrap:nowrap;
  `}
`;
const FeatureContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > :first-child {
    padding-top: 0;
  }

  ${media.large`
    justify-content: space-between;
    flex-wrap:wrap;
    margin-left: 120px
  `}
`;
const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 40px 0;

  ${media.large`
    justify-content: space-between;
    flex-wrap:nowrap;
    padding-bottom: 80px;
    padding-top: 76px;
  `};
`;
export const MembershipExperiencePage = () => {
  const navigate = useNavigate();
  const {
    data: accountData,
    error: errorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();
  if (isFetchingAccountData || !accountData) {
    return <Loader />;
  }

  if (errorInGetAccount) {
    throwError("errorOnGetAccount", errorInGetAccount);
  }

  const membershipType = accountData.account.membershipType;
  // null membershipType could mean signup flow dropped out users or something wrong with their account. we will know if their membershipType is still null after completing the signup flow
  if (!membershipType) {
    throwError("errorOnB2CNoSubNoAccessOrNullMembershipType");
  }

  const currentSubscription = accountData.account.currentSubscription;

  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throwError("hubGeneralError", "No subscription found for user.");
  }

  // not all users have recurringFee, don't remove the "?" from recurringFee

  const amount = currentSubscription.recurringFee?.amount ?? 5.99;
  const currency = currentSubscription.recurringFee?.currency ?? "USD";

  const localisedPrice = amount.toLocaleString(undefined, {
    style: "currency",
    currency: currency,
  });

  const priceToShow = `${localisedPrice} ${currency}`;

  const price_text = t(
    periodToProductPriceKey(
      currentSubscription.recurringFee?.billingPeriod ?? BillingPeriod.Months,
    ),
    {
      subscription_price: priceToShow,
    },
  );
  const b2cMemberText = t("membership_hub_experience_subtitle", {
    subscription_price: price_text,
  });
  const entilementMemberText = t(
    "membership_hub_entitlment_user_member_experience_page_subtitle",
  );

  // TODO might need to change how userType is derived here (atm backend returns membershipType as B2C for InActive B2B)
  const SUBTITLE_PRICE_TEXT =
    isEntitlementMember(membershipType) ||
    currentSubscription.channel === Channel.b2b ||
    !currentSubscription.recurringFee
      ? entilementMemberText
      : b2cMemberText;
  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t("membership_hub_experience_title")}
        onBackClick={() => navigate(routes.index)}
        subTitle={SUBTITLE_PRICE_TEXT}
      />
      <MainContainer>
        <CardContainer>
          <VerticalCard
            src={"membership_experience_sleep.webp"}
            alt={"Indepth card"}
            text={t("membership_hub_experience_card_indepth")}
          />
          <VerticalCard
            src={"membership_experience_app.webp"}
            alt={"Personalized card"}
            text={t("membership_hub_experience_card_personalized")}
          />
          <VerticalCard
            src={"membership_experience_w_health.webp"}
            alt={"Bodytemp card"}
            text={t("membership_hub_experience_card_body_temp")}
          />
          <VerticalCard
            src={"membership_experience_early_detection.webp"}
            alt={"Live HR card"}
            text={t("membership_hub_experience_card_heart_rate")}
          />
        </CardContainer>
        <TextContainer>
          <HeaderText
            $fontSize={"xxlarge"}
            $fontWeight={"300"}
            $lineHeight={"42px"}
          >
            {t("membership_hub_experience_title_body")}
          </HeaderText>
          <FeatureContainer>
            <FeatureText
              header={t("membership_hub_experience_subtitle_features")}
              text={t("membership_hub_experience_text_features")}
              icon={<StyledCheckIcon />}
            />
            <FeatureText
              header={t(
                "membership_hub_experience_subtitle_latest_and_greatest",
              )}
              text={t("membership_hub_experience_text_latest_and_greatest")}
              icon={<StyledUpdatesIcon />}
            />
            <FeatureText
              header={t("membership_hub_experience_subtitle_insights")}
              text={t("membership_hub_experience_text_insights")}
              icon={<StyledSelfImprovementIcon />}
            />
            <FeatureText
              header={t(
                "membership_hub_experience_subtitle_exclusive_promotions",
              )}
              text={t("membership_hub_experience_text_exclusive_promotions")}
              icon={<StyledPromotionsIcon />}
            />
          </FeatureContainer>
        </TextContainer>
      </MainContainer>
    </>
  );
};
