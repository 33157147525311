// Common calculation utils with Big.js to avoid any floating-point errors

import { Big } from "big.js";

export const preciseSum = (...values: number[]): number => {
  const total = values.reduce((acc, v) => acc.plus(v), Big(0));
  return total.toNumber();
};

export const preciseSubtraction = (v1: number, v2: number): number => {
  return Big(v1).minus(v2).toNumber();
};

export const preciseMultiplication = (...values: number[]): number => {
  const total = values.reduce((acc, v) => acc.mul(v), Big(1));
  return total.toNumber();
};
