import { ChangeEventHandler } from "react";

import { t } from "translations";

import { Pagination, usePagination } from "apps-common/components/Pagination";
import { Currency, Invoice } from "apps-common/types";
import { logger } from "apps-common/utils/logger";
import { CTALoader } from "ui";
import { DownloadIcon } from "ui/components/Icons";
import { Background } from "ui/styles/layout";

import { useGetInvoices } from "../../hooks/useGetInvoices";
import { clientDownloadFile, zipMultipleFiles } from "../../utils/fileDownload";

import {
  DownloadButton,
  InvoiceComponent,
  Ul,
  ViewContainer,
} from "./commonComponents";
import { FileSelectionAction } from "./fileSelectionReducer";

const PAGESIZE = 10;

interface InvoiceViewProps {
  id: string;
  invoices: Invoice[];
  currency: Currency;
  selectedInvoices: string[];
  dispatch: React.Dispatch<FileSelectionAction>;
}

export const InvoiceView = ({
  id,
  invoices,
  currency,
  selectedInvoices,
  dispatch,
}: InvoiceViewProps) => {
  const { isFetching, refetch } = useGetInvoices(selectedInvoices);

  const { currentPageObjects: pagedInvoices } = usePagination(
    PAGESIZE,
    invoices,
  );

  const invoiceComponents = pagedInvoices.map(
    ({ id, amount, compensationType, date }) => {
      const isCompensated = !!compensationType;
      const isSelected = selectedInvoices.includes(id);
      const dateOrYear = new Date(date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { checked } = e.target;
        if (checked) {
          dispatch({ type: "add", filesToAdd: [id] });
        } else {
          dispatch({ type: "delete", fileToDelete: id });
        }
      };

      return (
        <InvoiceComponent
          key={id}
          amountText={
            isCompensated
              ? t("membership_hub_invoice_compensated")
              : amount.toLocaleString(undefined, {
                  style: "currency",
                  currency,
                })
          }
          dateOrYear={dateOrYear}
          isSelected={isSelected}
          onChange={onChange}
        />
      );
    },
  );

  const onDownloadClick = async () => {
    logger.debug("Selected invoices", { selectedInvoices });
    await refetch().then((fetchedInvoices) => {
      logger.debug("Fetched invoices", { fetchedInvoices });

      if (!fetchedInvoices) {
        logger.debug("Invoice refetch returned undefined", {
          fetchedInvoices,
        });
        return;
      }

      if (fetchedInvoices.length > 1) {
        void zipMultipleFiles(
          fetchedInvoices.map((d) => ({
            data: d.file,
            fileName: `invoice_${d.invoiceId}.pdf`,
          })),
          "oura-invoices.zip",
        );
      } else {
        clientDownloadFile(
          fetchedInvoices[0].file,
          "application/pdf",
          `invoice_${fetchedInvoices[0].invoiceId}.pdf`,
        );
      }
    });
  };

  return (
    <ViewContainer role="tabpanel" id={id}>
      <Background>
        <Ul>{invoiceComponents}</Ul>
      </Background>
      <Pagination pageSize={PAGESIZE} paginatedObjects={invoices} />
      <DownloadButton
        $margin="24px 0"
        disabled={selectedInvoices.length === 0 || isFetching}
        onClick={onDownloadClick}
      >
        {isFetching && <CTALoader />}
        {!isFetching && (
          <>
            {t("membership_hub_download_invoices")}
            <DownloadIcon />
          </>
        )}
      </DownloadButton>
    </ViewContainer>
  );
};
